<template>
  <!-- Start Content-->
  <div>
    <div class="d-flex justify-content-center p-5" v-if="isLoading">
      <div class="spinner-border m-12" role="status">
        <span class="sr-only">
          <div class="lang" v-html="lan.loading"></div>...
        </span>
      </div>
    </div>
    <div v-else-if="!jsonErr && !checkKeys.length">
      <!-- 表示項目無し -->
      <code>
        <div class="lang" v-html="lan.no_display_item_selected"></div>
      </code>
    </div>
    <div class="responsive-table-plugin" v-else-if="!jsonErr">
      <div class="table-rep-plugin">
        <div class="table-responsive" style="transform: scaleY(-1);" >
         
          <div style="transform: scaleY(-1);">
            <table class="table table-striped focus-on">
              <thead>
                <tr>
                  <th
                    v-for="(tableHeader,index) in tableHeaders"
                    :key="tableHeader"
                    :class="[('table-row-' + tableHeader),(index == 0 ? 'my-th-width': '')]"
                    v-show="checkKeys.includes(tableHeader)"
                  >
                    <!-- {{ tableHeadersShow[index] }} -->
                    <span v-html="getLanguage(tableHeader)"></span>
                  </th>

                  <!-- <th></th> -->
                </tr>
              </thead>
              <tbody v-if="jsonObj.count && checkKeys.length">
                <tr
                  v-for="(obj, idNum) in listData"
                  :key="idNum"
                  :class="{ 'emptyd' : obj['資産の部']!=undefined && (obj['資産の部']=='負債の部' || obj['資産の部']=='資本の部'),'recommend':viewParams.randIndex==1 && cur_recommend==idNum }"
                >
                  <list-table-row2
                    :row="obj"
                    :checkList="checkKeys"
                    :showChart="isShowChart(idNum)"
                  />
                </tr>
              </tbody>
            </table>
          </div>
          <div style="transform: scaleY(-1);" v-if="viewParams.name!='corp_offices' && viewParams.name!='corp_history'">
            <div style="float:left;font-size:14px;color:#aaa">単位：百万円</div>
            <div style="float:right;">
              <a
                style="color:#ccc"
                href="https://cdn2.cbredata.com/docs/%E8%B2%A1%E5%8B%99%E8%AB%B8%E8%A1%A8%E9%96%8B%E7%A4%BA%E4%BE%8B.pdf"
                target="_blank"
              >出典：有価証券報告書</a>
            </div>
          </div>
          <div
            class="row"
            style="min-height:300px;width:100%;margin-bottom:20px;transform: scaleY(-1);"
            v-if="viewParams.name=='corp_BS'"
          >
            <div class="col-4">
              <apexchart type="pie" :options="options" :series="series"></apexchart>
            </div>
            <div class="col-4">
              <apexchart type="bar" :options="options_bar" :series="series_bar"></apexchart>
            </div>
            <div class="col-4">
              <apexchart type="line" :options="options_line" :series="series_line"></apexchart>
            </div>
          </div>
        </div>
        <!-- end .table-responsive -->
      </div>
      <!-- end .table-rep-plugin-->
      <div class="p-2">
        <code v-if="!jsonObj.total_count">
          <div class="lang" v-html="lan.no_data"></div>
        </code>
      </div>
    </div>
    <!-- end .responsive-table-plugin-->
    <div v-else>
      <code>{{(viewParams.name!='corp_history'? "データ取得に失敗しました。":"過去営業歴がありません。")}}</code>
    </div>
  </div>
  <!-- container -->
</template>

<script>
import ListTableRow2 from "./ListTableRow2.vue";
import axios from "axios";
import { inject } from "vue";
export default {
  name: "ListTable",
  props: {
    viewParams: {
      name: {
        type: String,
        default: "",
      },
      apiUrl: {
        type: String,
        default: "",
      },
      randIndex: {
        type: Number,
        default: 0,
      },
      articleView: String,
      articleParamId: String,
    },
  },
  components: {
    ListTableRow2,
  },
  data() {
    return {
      isRand: 0,
      cur_recommend: 0,
      offset: 0,
      options: {},
      options_bar: {},
      series: null,
      series_bar: null,
      options_line: {},
      series_line: null,

      listStrage: {
        query: "",
        limit: 100,
        paged: 1,
      },
      maxPage: 0,
      checkKeys: [],
      jsonObj: {
        offset: 0,
        count: 0,
        total_count: 0,
        data: [Object],
      },
      tableHeaders: [],
      tableHeadersShow: [],
      listData: [Object],
      isFocus: -1,
      ddMenu: false,
      jsonErr: false,
      isLoading: true,
      downloadAlert: "",
      lan: inject("global").language_data,
      lan_help: inject("global").language_help_data,
    };
  },
  computed: {},
  mounted() {
    if (localStorage.getItem(this.viewParams.name + "_limit")) {
      this.listStrage.limit = localStorage.getItem(
        this.viewParams.name + "_limit"
      );
    }
    if (sessionStorage.getItem(this.viewParams.name + "_query")) {
      this.listStrage.query = sessionStorage.getItem(
        this.viewParams.name + "_query"
      );
    }
    if (sessionStorage.getItem(this.viewParams.name + "_paged")) {
      this.listStrage.paged = Number(
        sessionStorage.getItem(this.viewParams.name + "_paged")
      );
    }

    axios
      .get(this.viewParams.apiUrl, {
        params: {
          offset: (this.listStrage.paged - 1) * this.listStrage.limit, // 開始位置
          limit: this.listStrage.limit, //表示件数
          q: this.listStrage.query, //キーワード
        },
      })
      .then((response) => {
        this.jsonObj = response.data;
        var tableHeaders = [
          "Description",
          "Subject",
          "Comments_Truncated__c",
          "Owner_Name__c",
          "StartDateTime",
          "EndDateTime",
        ];
        var tableHeadersShow = [
          this.lan.description,
          this.lan.subject,
          this.lan.comments_truncated,
          this.lan.owner_name,
          this.lan.start_date_time,
          this.lan.end_date_time,
        ];
        this.tableHeaders =
          this.viewParams.name == "corp_history"
            ? tableHeaders
            : this.jsonObj.headers;
        this.tableHeadersShow = tableHeadersShow;

        //読み込み時点のlocalstrageのtableheadersを取得
        var localTableHeaders =
          localStorage.getItem(this.viewParams.name + "_tableHeaders") !=
            null &&
          localStorage.getItem(this.viewParams.name + "_tableHeaders") != ""
            ? JSON.parse(
                localStorage.getItem(this.viewParams.name + "_tableHeaders")
              )
            : this.tableHeaders;
        this.listData = this.jsonObj.data;

        var tdata = this.jsonObj.data;

        if (tdata.length > 0) {
          if (this.viewParams.randIndex == 1) {
            this.cur_recommend = Math.ceil(Math.random() * (tdata.length - 1));
          }

          var tj = [[], []];
          var tj_bar = [0, 0, 0];
          var zc_total = [];
          var zc_jd = [];
          for (var d in tdata) {
            if (tdata[d]["資産の部"] == "流動資産合計") {
              tj[0].push(this.$language("流動資産合計"));
              tj[1].push(
                isNaN(
                  parseInt(
                    tdata[d][
                      this.tableHeaders[this.tableHeaders.length - 1]
                    ].replace(/,/g, "")
                  )
                )
                  ? 0
                  : parseInt(
                      tdata[d][
                        this.tableHeaders[this.tableHeaders.length - 1]
                      ].replace(/,/g, "")
                    )
              );
            } else if (tdata[d]["資産の部"] == "有形固定資産合計（純額）") {
              tj[0].push(this.$language("有形固定資産合計（純額）"));
              tj[1].push(
                isNaN(
                  parseInt(
                    tdata[d][
                      this.tableHeaders[this.tableHeaders.length - 1]
                    ].replace(/,/g, "")
                  )
                )
                  ? 0
                  : parseInt(
                      tdata[d][
                        this.tableHeaders[this.tableHeaders.length - 1]
                      ].replace(/,/g, "")
                    )
              );
            } else if (tdata[d]["資産の部"] == "無形固定資産（純額）") {
              tj[0].push(this.$language("無形固定資産（純額）"));
              tj[1].push(
                isNaN(
                  parseInt(
                    tdata[d][
                      this.tableHeaders[this.tableHeaders.length - 1]
                    ].replace(/,/g, "")
                  )
                )
                  ? 0
                  : parseInt(
                      tdata[d][
                        this.tableHeaders[this.tableHeaders.length - 1]
                      ].replace(/,/g, "")
                    )
              );
            } else if (tdata[d]["資産の部"] == "資産合計") {
              for (var h in this.tableHeaders) {
                if (h > 0) {
                  zc_jd.push(this.tableHeaders[h]);
                  zc_total.push(
                    isNaN(
                      parseInt(tdata[d][this.tableHeaders[h]].replace(/,/g, ""))
                    )
                      ? 0
                      : parseInt(
                          tdata[d][this.tableHeaders[h]].replace(/,/g, "")
                        )
                  );
                }
              }

              tj_bar[0] = isNaN(
                parseInt(
                  tdata[d][
                    this.tableHeaders[this.tableHeaders.length - 1]
                  ].replace(/,/g, "")
                )
              )
                ? 0
                : parseInt(
                    tdata[d][
                      this.tableHeaders[this.tableHeaders.length - 1]
                    ].replace(/,/g, "")
                  );
            } else if (tdata[d]["資産の部"] == "負債合計") {
              tj_bar[1] = isNaN(
                parseInt(
                  tdata[d][
                    this.tableHeaders[this.tableHeaders.length - 1]
                  ].replace(/,/g, "")
                )
              )
                ? 0
                : parseInt(
                    tdata[d][
                      this.tableHeaders[this.tableHeaders.length - 1]
                    ].replace(/,/g, "")
                  );
            } else if (tdata[d]["資産の部"] == "資本合計") {
              tj_bar[2] = isNaN(
                parseInt(
                  tdata[d][
                    this.tableHeaders[this.tableHeaders.length - 1]
                  ].replace(/,/g, "")
                )
              )
                ? 0
                : parseInt(
                    tdata[d][
                      this.tableHeaders[this.tableHeaders.length - 1]
                    ].replace(/,/g, "")
                  );
            }
          }
        }
        var that = this;
        if (tj[0].length > 0) {
          console.log(tj);
          this.options = {
            chart: {
              width: 400,
              height: 300,
              type: "pie",
              toolbar: {
                show: false,
              },
            },
            yaxis: {
              labels: {
                formatter: function (value) {
                  return that.format_money(value);
                },
              },
            },
            labels: tj[0],
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 300,
                  },
                  legend: {
                    position: "bottom",
                  },
                },
              },
            ],
          };
          this.series = tj[1];
        }
        if (tj_bar.length > 0) {
          console.log(tj_bar);
          this.options_bar = {
            chart: {
              type: "bar",
              height: "100%",
              stacked: true,
              labels: {
                formatter: function (value) {
                  return that.format_money(value);
                },
              },
              toolbar: {
                show: false,
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              },
            ],
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 10,
              },
            },

            xaxis: {
              categories: [this.$language("資産"), this.$language("負債")],
            },
            yaxis: {
              labels: {
                formatter: function (value) {
                  return that.format_money(value);
                },
              },
            },
            legend: {
              position: "right",
              offsetY: 40,
            },
            fill: {
              opacity: 1,
            },
          };
          this.series_bar = [
            {
              name: this.$language("資産合計"),
              data: [tj_bar[0], 0],
            },
            {
              name: this.$language("負債合計"),
              data: [0, tj_bar[1]],
            },
            {
              name: this.$language("資本合計"),
              data: [0, tj_bar[2]],
            },
          ];
        }
        if (zc_total.length > 0) {
          this.series_line = [
            {
              name: this.$language("資産合計"),
              data: zc_total,
            },
          ];
          console.log(zc_jd);
          this.options_line = {
            chart: {
              height: 300,
              type: "line",
              zoom: {
                enabled: false,
              },
              toolbar: {
                show: false,
              },
            },

            dataLabels: {
              enabled: false,
            },

            title: {
              text: this.$language("資産合計"),
              align: "left",
            },
            grid: {
              row: {
                colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.5,
              },
            },
            xaxis: {
              categories: zc_jd,
            },
            yaxis: {
              labels: {
                formatter: function (value) {
                  return that.format_money(value);
                },
              },
            },
          };
        }
        this.maxPage = Math.ceil(
          this.jsonObj.total_count / this.listStrage.limit
        );
        this.checkKeys = JSON.parse(
          localStorage.getItem(this.viewParams.name + "_checked")
        );

        // localstrageにないtableheaderをチェック済リストに追加
        let addTableHeaders = this.tableHeaders.filter(
          (item) => localTableHeaders.indexOf(item) == -1
        );
        if (addTableHeaders.length) {
          this.checkKeys.push.apply(this.checkKeys, addTableHeaders);
        }
        if (this.checkKeys == null || this.checkKeys.length == 0) {
          this.checkKeys = this.tableHeaders;
        }
        // tableheaderから無くなったheaderkeyがチェック済リストにあれば削除
        let delTableHeaders = this.checkKeys.filter(
          (item) => this.tableHeaders.indexOf(item) == -1
        );
        if (delTableHeaders.length) {
          let filterVal = this.checkKeys.filter(
            (item) => delTableHeaders.indexOf(item) == -1
          );
          this.checkKeys = filterVal;
        }

        // APIで取得した新しいtableheadersをlocalstrageに保存
        localStorage.setItem(
          this.viewParams.name + "_tableHeaders",
          JSON.stringify(this.tableHeaders)
        );

        this.isLoading = false;
      })
      .catch((error) => {
        console.log(error), (this.jsonErr = true), (this.isLoading = false);
      });
  },
  methods: {
    getLanguage(tableHeader) {
      return this.$language(tableHeader);
    },
    isShowChart(index) {
      if (this.viewParams.name == "corp_PL") {
        if (index == 0 || index == 8 || index == 25) {
          return true;
        }
      }
      if (this.viewParams.name == "corp_CF") {
        if (index == 0 || index == 1) {
          return true;
        }
      }
      return false;
    },
    format_money: function (num) {
      if (isNaN(num) || num == null || num == 0) {
        return num;
      }
      var result = "",
        counter = 0;
      //if(parseInt(num) == num) return num;
      num = num.toString();
      var numa = num.split(".");
      num = numa[0];
      for (var i = num.length - 1; i >= 0; i--) {
        counter++;
        result = num.charAt(i) + result;
        if (!(counter % 3) && i != 0 && num.charAt(i - 1) != "-") {
          result = "," + result;
        }
      }
      return result;
    },
    toggleDDMenu: function () {
      this.ddMenu = !this.ddMenu;
    },
    allCheck: function () {
      this.checkKeys = this.tableHeaders;
    },
    allUncheck: function () {
      this.checkKeys = [];
    },
    toggleFocus: function (idNum) {
      this.isFocus = idNum;
    },
    search: function () {
      this.isLoading = true;
      localStorage.setItem(
        this.viewParams.name + "_limit",
        this.listStrage.limit
      );
      sessionStorage.setItem(
        this.viewParams.name + "_query",
        this.listStrage.query
      );
      sessionStorage.setItem(this.viewParams.name + "_paged", 1);
      axios
        .get(this.viewParams.apiUrl, {
          params: {},
        })
        .then(
          (response) => (
            (this.jsonObj = response.data),
            (this.tableHeaders = this.jsonObj.headers),
            (this.listData = this.jsonObj.data),
            (this.maxPage = Math.ceil(
              this.jsonObj.total_count / this.listStrage.limit
            )),
            (this.listStrage.paged = 1),
            (this.isLoading = false)
          )
        )
        .catch((error) => {
          console.log(error), (this.jsonErr = true), (this.isLoading = false);
        });
    },
    pagenateNum: function (num) {
      this.isLoading = true;
      axios
        .get(this.viewParams.apiUrl, {
          params: {
            offset: (num - 1) * this.listStrage.limit, //開始位置
            limit: this.listStrage.limit, //表示件数
            q: this.listStrage.query, //キーワード
          },
        })
        .then(
          (response) => (
            (this.jsonObj = response.data),
            (this.tableHeaders = this.jsonObj.headers),
            (this.listData = this.jsonObj.data),
            (this.maxPage = Math.ceil(
              this.jsonObj.total_count / this.listStrage.limit
            )),
            (this.listStrage.paged =
              Math.round(this.jsonObj.offset / this.listStrage.limit) + 1),
            sessionStorage.setItem(
              this.viewParams.name + "_paged",
              this.listStrage.paged
            ),
            (this.isLoading = false)
          )
        )
        .catch((error) => {
          console.log(error), (this.jsonErr = true), (this.isLoading = false);
        });
    },
    pagenateKey: function (key) {
      switch (key) {
        case "next":
          if (this.listStrage.paged < this.maxPage) {
            this.isLoading = true;
            this.offset = this.listStrage.limit * this.listStrage.paged;
          }
          break;
        case "prev":
          if (this.listStrage.paged > 1) {
            this.isLoading = true;
            this.offset = this.listStrage.limit * (this.listStrage.paged - 2);
          }
          break;
        case "first":
          if (this.listStrage.paged > 1) {
            this.isLoading = true;
            this.offset = 0;
          }
          break;
        case "last":
          if (this.listStrage.paged < this.maxPage) {
            this.isLoading = true;
            this.offset = this.listStrage.limit * (this.maxPage - 1);
          }
          break;
        default:
          this.isLoading = false;
          console.log("Non-existent key");
      }
      if (this.isLoading) {
        axios
          .get(this.viewParams.apiUrl, {
            params: {
              offset: this.offset, //開始位置
              limit: this.listStrage.limit, //表示件数
              q: this.listStrage.query, //キーワード
            },
          })
          .then(
            (response) => (
              (this.jsonObj = response.data),
              (this.tableHeaders = this.jsonObj.headers),
              (this.listData = this.jsonObj.data),
              (this.maxPage = Math.ceil(
                this.jsonObj.total_count / this.listStrage.limit
              )),
              (this.listStrage.paged =
                Math.round(this.jsonObj.offset / this.listStrage.limit) + 1),
              sessionStorage.setItem(
                this.viewParams.name + "_paged",
                this.listStrage.paged
              ),
              (this.isLoading = false)
            )
          )
          .catch((error) => {
            console.log(error), (this.jsonErr = true), (this.isLoading = false);
          });
      }
    },
    pageHide: function (num) {
      if (num < this.listStrage.paged - 4 || num > this.listStrage.paged + 4) {
        return true;
      }
    },
    listDownload: function () {
      let xmldata;
      axios
        .post(
          this.viewParams.apiUrl + "download",
          {
            data: this.listData,
            items: this.checkKeys,
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          xmldata = response.data;
          this.$nextTick(() => {
            let blob = new Blob([xmldata]);
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "corps.xlsx";
            link.click();
          });
          this.downloadAlert = "";
          this.isLoading = false;
        })
        .catch((error) => {
          this.downloadAlert = this.$language("ダウンロードに失敗しました。");
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  watch: {
    checkKeys(newCheckKeys) {
      localStorage.setItem(
        this.viewParams.name + "_checked",
        JSON.stringify(newCheckKeys)
      );
    },
    isLoading: function () {
      if (!this.isLoading) {
        this.$nextTick(() => {
          window.parent.postMessage(["h"], "*");
        });
      } else {
        //ローディング開始のタイミングでisFocusをリセット
        this.isFocus = -1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.my-th-width {
  width: 230px;
}
.form-inline {
  flex-wrap: nowrap;
}

.toolbar-download {
  margin-left: auto;
  margin-right: 1.5rem;
}

.toolbar-display-count {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}

@media screen and (max-width: 576px) {
  .toolbar-search {
    flex: 1;
  }

  .toolbar-download {
    justify-content: flex-end;
    margin-bottom: 0.75rem;
    margin-right: 0;
    order: -1;
    width: 100%;
  }

  .toolbar-display {
    justify-content: flex-end;
    margin-top: 0.5em;
    width: 100%;
  }
}

.form-search {
  position: relative;
}

.form-search::before {
  content: "\f002";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  height: 1em;
  width: 1em;
}

.ddmenu-modal {
  position: relative;
  z-index: 128;
}

.ddmenu {
  background: #fff;
  border-radius: 0.15rem;
  left: 50% !important;
  padding: 2.5rem 2.5rem 1.5rem;
  position: fixed;
  top: 2.5rem !important;
  transform: translateX(-50%) !important;
  width: 80vw;
  z-index: 16;
}

.ddmenu .btn-dd-close {
  position: absolute !important;
  right: -0.5rem;
  top: -0.5rem;
}

.dropdown-menu.show {
  align-content: flex-start;
  animation: none !important;
  display: flex;
  flex-wrap: wrap;
  left: auto !important;
  margin-bottom: 1rem;
  max-height: 40rem;
  overflow-y: scroll !important;
  position: static !important;
  top: auto !important;
  width: 100%;
}

.dropdown-menu.show li {
  flex: 0 1 32.5%;
  word-break: break-all;
}

.ddmenu-bg {
  background: rgba(0, 0, 0, 0.25);
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 8;
}

.fade-leave-active {
  transition: 0.25s;
  opacity: 0;
}

.fade-enter-active {
  transition: 0.25s;
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}
@media screen and (max-width: 1200px) {
  .col-4 {
    width: 100%;
    flex: 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .dropdown-menu.show li {
    flex-basis: 50%;
  }
}

@media screen and (max-width: 500px) {
  .dropdown-menu.show li {
    flex-basis: 100%;
  }
}

.table-responsive {
  overflow: auto !important;
}

table.focus-on tbody tr:hover,
table.focus-on tfoot tr:hover {
  cursor: unset;
}

.table thead tr:last-child th {
  box-shadow: 0 -0.1rem #dee2e6 inset;
  border-bottom: none;
  position: sticky;
  top: -1px;
  z-index: 8;
}

.table {
  table-layout: fixed;
}
.table.table-striped td {
  min-width: 14.5em;
  white-space: normal !important;
  word-wrap: break-word;
}

.table.table-striped .table-focus-on-td {
  align-items: center;
  min-width: auto;
  padding: 0.35rem !important;
  position: sticky;
  right: 0;
}

.table-focus-on-td .btn {
  white-space: nowrap;
  transition: 0.15s;
}

// transition
.slide-btn-leave-to.btn {
  opacity: 0;
}

.slide-btn-enter-from.btn {
  transform: translateX(-1rem);
  opacity: 0;
}

.slide-btn-enter-to.btn {
  transform: translateX(0);
}

.footable-page.hide {
  display: none;
}

.table-striped tbody tr:nth-of-type(odd) {
  background: #fff !important;
}
.table-striped thead th {
  background: #003f2d !important;
  color: #fff;
}
.emptyd {
  background: #003f2d !important;
  color: #fff;
}
.recommend {
  background: #003f2d !important;
  color: #fff;
}
</style>